import { useRef, useState } from 'react';
import useOnClickOutside from '../hooks/useOnClickOutside';

import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FancyDropdown = ({
  value,
  options,
  getValue
}: {
  options: {
    id?: number;
    value: string;
    label: string;
  }[];
  value: string;
  getValue: (val: object) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useOnClickOutside(ref, () => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option: any) => {
    getValue(option);
    setIsOpen(false);
  };

  return (
    <div ref={ref} className="dropdown">
      <div
        className={`dropdown__header ${isOpen && 'dropdown__header--open'}`}
        onClick={toggleDropdown}
      >
        <FontAwesomeIcon icon={faNewspaper} />
        <p className="dropdown__value">{value || 'Select an article stage'}</p>
        <i className={`arrow ${isOpen ? 'up' : 'down'}`} />
      </div>

      <ul className={`dropdown__options ${isOpen && 'dropdown__options--open'}`}>
        {options.map((option, index) => (
          <li key={index} onClick={() => selectOption(option)}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FancyDropdown;
