import axios from 'axios';
import JSZip from 'jszip';
import { Fragment, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import bronze from '../assets/bronze.png';
import download from '../assets/download.png';
import gold from '../assets/gold.png';
import silver from '../assets/silver.png';

type ShowPDFProps = {
  pdfs: any;
  getArticle: (a: any) => void;
};

const MEDALS_ICONS = [gold, silver, bronze];
const MEDALS = ['gold', 'silver', 'bronze'];

const ShowPDF = ({ pdfs, getArticle }: ShowPDFProps) => {
  const downloadPDFsAsZip = async () => {
    try {
      const zip = new JSZip();

      const promises = pdfs.map(async (item: any, index: number) => {
        const name = `${index}-layout-${MEDALS[index]}.pdf`; // Provide a unique name for each PDF
        const response = await axios.get(item.page_url, { responseType: 'arraybuffer' });
        zip.file(name, response.data);
      });

      await Promise.all(promises);

      zip
        .generateAsync({ type: 'blob' })
        .then((content) => {
          const zipBlob = new Blob([content], { type: 'application/zip' });
          const zipUrl = URL.createObjectURL(zipBlob);

          const link = document.createElement('a');
          link.href = zipUrl;
          link.download = 'articles.zip';
          link.click();

          // Clean up
          URL.revokeObjectURL(zipUrl);
        })
        .catch((e) => console.error(e));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadButton = (url: string) => {
    const arr = url.split('/');
    const name = arr[arr.length - 1];
    const pdfUrl = url;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.download = name; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [numPages, setNumPages] = useState<number>();
  const [checkboxValue, setCheckBoxValue] = useState<string | undefined>(undefined);

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url
  ).toString();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function handleCheckBoxChange(e: any) {
    if (e.target.value === checkboxValue) {
      setCheckBoxValue(undefined);
      getArticle(null);
    } else {
      setCheckBoxValue(e.target.value);
      getArticle(pdfs[e.target.value]);
    }
  }

  return (
    <>
      <div className="download-all" onClick={downloadPDFsAsZip}>
        {' '}
        Download All
        <img src={download} alt="" />
      </div>
      <div className="pdf">
        {pdfs.map((item: any, i: number) => {
          return (
            <Fragment key={i}>
              <Document
                file={item.page_url}
                onLoadSuccess={onDocumentLoadSuccess}
                key={`document_${i}`}
              >
                {Array.from(new Array(numPages), (_el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}

                <div className="pdf-checkbox">
                  <input
                    value={i}
                    type="checkbox"
                    onChange={handleCheckBoxChange}
                    checked={Number(checkboxValue) === i}
                  />
                </div>
                <div className="pdf-medal">
                  <img src={MEDALS_ICONS[i]} alt="" />
                </div>
                <div className="pdf-download" onClick={() => handleDownloadButton(item.page_url)}>
                  <img src={download} alt="" />
                </div>
              </Document>
              <hr style={{ border: '1px solid #202346', margin: '40px 0px' }} key={`hr_${i}`} />
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default ShowPDF;
