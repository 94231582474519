import React, { useEffect, useState } from 'react';
import Button from './Button';

interface Content {
  identifier: string;
  content: {
    text?: { insert: string }[];
    image?: { id: string };
    src?: { insert: string }[];
  };
}

interface Article {
  articletype: string;
  unity_article_id: string;
  type_code: string | number | boolean;
  data: {
    content: Content[];
  };
}

interface SelectImageModalProps {
  data: { articles: Article[] };
  onDone: (updatedData: { articles: Article[] }) => void;
}

const SelectImageModal: React.FC<SelectImageModalProps> = ({ data, onDone }) => {
  const [selectedImages, setSelectedImages] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const initialSelectedImages = data.articles.reduce(
      (acc, article) => {
        const firstImage = article.data.content.find((content) => content.identifier === 'image');
        if (firstImage?.content.image?.id) {
          acc[article.unity_article_id] = firstImage.content.image.id;
        }
        return acc;
      },
      {} as { [key: string]: string }
    );

    setSelectedImages(initialSelectedImages);
  }, [data.articles]);

  const moveImageToFirst = (content: Content[], selectedImageId: string | undefined) => {
    if (!selectedImageId) return content;

    const imageIndex = content.findIndex(
      (obj) => obj.identifier === 'image' && obj.content.image?.id === selectedImageId
    );

    if (imageIndex > -1) {
      const selectedImage = content.splice(imageIndex, 1)[0];
      content.unshift(selectedImage);
    }
    return content;
  };

  const handleImageClick = (articleId: string, imageId: string) => {
    setSelectedImages((prevSelected) => ({
      ...prevSelected,
      [articleId]: imageId
    }));
  };

  const handleDone = () => {
    const updatedData = {
      ...data,
      articles: data.articles.map((article) => {
        const selectedImageId = selectedImages[article.unity_article_id];
        return {
          ...article,
          data: {
            ...article.data,
            content: moveImageToFirst(article.data.content, selectedImageId)
          }
        };
      })
    };

    onDone(updatedData);
  };

  return (
    <div className="select-image-wrapper">
      {data.articles.map((article) => {
        const titleObj = article.data.content.find((obj) => obj.identifier === 'title');

        return (
          <div key={article.unity_article_id} className="select-image-content">
            <div className="select-image-title-wrapper">
              <div className="select-image-title">
                {titleObj?.content?.text?.[0]?.insert || 'Untitled'}
                {' - '}
                {article.articletype}
              </div>
            </div>
            <div className="select-image-items">
              {article.data.content
                .filter((obj) => obj.identifier === 'image')
                .map((obj) => (
                  <div
                    key={obj.content.image?.id}
                    className={`select-image-item ${
                      selectedImages[article.unity_article_id] === obj.content.image?.id
                        ? 'selected'
                        : ''
                    }`}
                    onClick={() =>
                      handleImageClick(article.unity_article_id, obj.content.image?.id || '')
                    }
                  >
                    {obj.content.src?.[0]?.insert && (
                      <img
                        src={obj.content.src[0].insert}
                        alt={`Image for article ${article.unity_article_id}`}
                        className="select-image-item-image"
                        loading="lazy"
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        );
      })}
      <div className="button-wrapper">
        <Button text="Regenerate" onClick={handleDone} />
      </div>
    </div>
  );
};

export default SelectImageModal;
