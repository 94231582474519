import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';
import { ChangeEvent, SetStateAction, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { getDeskNetElements, getXMLInputfiles } from '../api';

import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import config from '../config';
import {
  deskNetCategories,
  deskNetCustomFields,
  deskNetElementStatus,
  deskNetPlatforms,
  deskNetPublicationStatus,
  deskNetTypes,
  printPublicationsCategories,
  publicationFrOnlineCode,
  publicationOnlineCode
} from '../constants/index';
import { CustomField, DeskNetElementsType, PublicationType, TableDataType } from '../types';
import ArticlesPlanningItem from './ArticlesPlanningItem';
import FancyDropdown from './FancyDropdown';
import MultiDropdown from './MultiDropdown';

type Props = any;

const ArticlesPlanning: React.FC<Props> = () => {
  const platformOptions = Object.entries(deskNetPlatforms).map(([value, label]) => ({
    value,
    label
  }));

  const [platform, setPlatform] = useState<{ value: string; label: string }>(() => {
    const savedPlatform = localStorage.getItem('platform');
    return savedPlatform
      ? JSON.parse(savedPlatform)
      : {
          value: config.node_env === 'development' ? '9407452' : '9418081',
          label: 'MANTEL'
        };
  });

  const printPublicationsCategoriesOptions = printPublicationsCategories[platform.label]
    ? printPublicationsCategories[platform.label].map((val) => {
        return {
          value: val,
          label: val
        };
      })
    : null;

  const [publicationsCategory, setPublicationsCategory] = useState(() => {
    const savedPublicationsCategory = localStorage.getItem('publicationsCategory');
    return savedPublicationsCategory ? JSON.parse(savedPublicationsCategory) : [];
  });

  const [elements, setElements] = useState<DeskNetElementsType[] | undefined>([]);

  const [planningDate, setPlanningDate] = useState<Date>(() => {
    const savedDate = localStorage.getItem('date');
    return savedDate
      ? new Date(JSON.parse(savedDate))
      : new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  });

  const [pagePlanning, setPagePlanning] = useState<any[]>([]);
  const [tableData, setTableData] = useState<TableDataType[]>([]);
  const [initialTableData, setInitialTableData] = useState<TableDataType[]>([]);

  function getCurretDateFormatted(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function getKeyByValue(object: { [x: string]: any }, value: string) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  async function getFileFromS3() {
    setPagePlanning([]);

    try {
      const presignedUrl = await getXMLInputfiles(
        platform.label,
        getCurretDateFormatted(planningDate)
      );

      const data = await axios.get(presignedUrl.data.url);

      const xmlContent = (data && data.data) || '';
      const options = {
        ignoreAttributes: false,
        attributeNamePrefix: '',
        allowBooleanAttributes: true
      };

      try {
        const parser = new XMLParser(options);
        const json = parser.parse(xmlContent);
        setPagePlanning(
          json.magazines.magazine.issues.issue.revisions.revision.printproducts.printproduct
        );
      } catch (err) {
        console.error('Error fetching file from S3:', err);
      }
    } catch (err) {
      console.error('Error fetching file from S3:', err);
      toast.warning(`Page planning is not ready yet for ${getCurretDateFormatted(planningDate)}`);
    }
  }

  useEffect(() => {
    getFileFromS3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform.value, planningDate]);

  useEffect(() => {
    const date = getCurretDateFormatted(planningDate);
    getDeskNetElements(platform.value, date)
      .then((res) => {
        setElements(res.data);
      })
      .catch((err) => {
        toast.error('Error fetching DeskNet elements');
        console.error('Error fetching DeskNet elements:', err);
      });
  }, [platform.value, planningDate]);

  function getIdFromUrl(urlString?: string) {
    if (!urlString) return undefined;
    const url = new URL(urlString);
    const pathname = url.pathname;
    const parts = pathname.split('/');
    const id = parts[parts.length - 1];
    return id;
  }

  useEffect(() => {
    if (publicationsCategory.length > 0) {
      setTableData(
        initialTableData.filter((data) =>
          publicationsCategory.some(
            (obj: { value: string | undefined }) => obj.value === data.main.mainPublicationsCategory
          )
        )
      );
    } else {
      setTableData(initialTableData);
    }
  }, [initialTableData, publicationsCategory]);

  function getCustomFieldByName(fieldName: string): CustomField | undefined {
    const field = deskNetCustomFields.find((f) => f.name === fieldName);
    if (field) {
      return { ...field };
    }
    return undefined;
  }

  useEffect(() => {
    if (elements && pagePlanning) {
      const res = pagePlanning.map((obj) => {
        return obj.pages.page.map((page: { items: any; folio: any }) => {
          const tableItem: any = {
            main: {
              folio: page.folio,
              itemName: page.items?.item?.name,
              studio_layout_id: page.items?.item?.foreignkey2,
              mainPublicationsCategory: page.items?.item?.editorialsection?.name,
              itemDate: planningDate
            },
            data: []
          };
          if (Array.isArray(page.items?.item)) {
            tableItem.main = {
              ...tableItem.main,
              itemName: page.items?.item.find((obj: { type: string }) => obj.type === 'story')
                ?.name,
              mainPublicationsCategory: page.items?.item.find(
                (obj: { type: string }) => obj.type === 'story'
              )?.editorialsection?.name,
              studio_layout_id: page.items?.item.find(
                (obj: { type: string }) => obj.type === 'story'
              )?.foreignkey2,
              adPosition: page.items?.item.find((obj: { type: string }) => obj.type === 'ad')
                ?.position
            };
          }

          elements.forEach((obj) => {
            if (
              obj.publications.some(
                (publication: PublicationType) =>
                  publication.category &&
                  JSON.stringify(publication.category) ===
                    getKeyByValue(deskNetCategories, tableItem.main.itemName)
              )
            ) {
              const publication = obj.publications.find(
                (pub: { platform: any }) => JSON.stringify(pub.platform) === platform.value
              );

              const publicationOnline = obj.publications.find(
                (pub: { platform: any }) =>
                  JSON.stringify(pub.platform) ===
                  (platform.label === 'Journaux Romandie'
                    ? publicationFrOnlineCode
                    : publicationOnlineCode)
              );

              const customTextLenght = obj.customFields.find(
                (custom: { id: number }) =>
                  custom.id ===
                  getCustomFieldByName(
                    platform.label === 'Journaux Romandie' ? 'Nombre de caractères' : 'Textlänge'
                  )?.id
              );

              const url_to_published_content = publication?.url_to_published_content
                ? publication.url_to_published_content
                : publicationOnline?.url_to_published_content;

              tableItem.data.push({
                id: obj?.id,
                elementStatus: deskNetElementStatus[obj?.elementStatus],
                publicationStatus: deskNetPublicationStatus[publication?.status || '1'],
                type: publication?.type ? deskNetTypes[publication?.type] : '',
                slug: obj.slug,
                textLenght: getCustomFieldByName(
                  platform.label === 'Journaux Romandie' ? 'Nombre de caractères' : 'Textlänge'
                )?.options?.find((obj) => obj.id === customTextLenght?.selectedOption[0])?.name,
                unityId: getIdFromUrl(url_to_published_content),
                deskNetUrl: obj.url,
                scope: publicationOnline?.scope || '',
                additional_image: 0,
                graph: { height: undefined, width: undefined }
              });
            }
          });

          return tableItem;
        });
      });

      setTableData(res.flat());
      setInitialTableData(res.flat());
    }
  }, [elements, pagePlanning, platform.value, planningDate]);

  function handleChangePlatform(obj: SetStateAction<{ value: string; label: string }>) {
    setPlatform(obj);
    localStorage.setItem('platform', JSON.stringify(obj));
    localStorage.removeItem('publicationsCategory');
    setPublicationsCategory([]);
  }

  function handleAddPublicationsCategory(obj: { value: string; label: string }) {
    const updatedValues = publicationsCategory.concat(obj);
    setPublicationsCategory(updatedValues);
    localStorage.setItem('publicationsCategory', JSON.stringify(updatedValues));
  }

  function handleRemovePublicationsCategory(obj: { value: string; label: string }) {
    const updatedValues = publicationsCategory.filter(
      (category: { value: string }) => category.value !== obj.value
    );
    setPublicationsCategory(updatedValues);
    localStorage.setItem('publicationsCategory', JSON.stringify(updatedValues));
  }

  function handleRemoveAllPublicationsCategory() {
    setPublicationsCategory([]);
    localStorage.setItem('publicationsCategory', JSON.stringify([]));
  }

  function handleChangeDate(date: Date) {
    localStorage.setItem('date', JSON.stringify(date));
    setPlanningDate(date);
  }

  function handleChangeGraphHeight(e: ChangeEvent<HTMLInputElement>, folio: string, id: number) {
    const newHeight = e.target.value;

    const updatedTableData = tableData.map((obj) =>
      obj.main.folio === folio
        ? {
            ...obj,
            data: obj.data?.map((data) =>
              data.id === id
                ? { ...data, graph: { ...data.graph, height: parseInt(newHeight) } }
                : data
            )
          }
        : obj
    );

    setTableData(updatedTableData);
  }

  function handleChangeGraphWidth(e: ChangeEvent<HTMLInputElement>, folio: string, id: number) {
    const newWidth = e.target.value;

    const updatedTableData = tableData.map((obj) =>
      obj.main.folio === folio
        ? {
            ...obj,
            data: obj.data?.map((data) =>
              data.id === id
                ? { ...data, graph: { ...data.graph, width: parseInt(newWidth) } }
                : data
            )
          }
        : obj
    );

    setTableData(updatedTableData);
  }
  function handleChangeAdditionalImage(obj: { value: string }, folio: string, id: number) {
    const newValue = obj.value;
    const updatedTableData = tableData.map((obj) =>
      obj.main.folio === folio
        ? {
            ...obj,
            data: obj.data?.map((data) =>
              data.id === id ? { ...data, additional_image: newValue } : data
            )
          }
        : obj
    );
    setTableData(updatedTableData);
  }

  // eslint-disable-next-line react/display-name
  const CustomInput = React.forwardRef<HTMLInputElement, any>(({ value, onClick }, ref) => (
    <button
      className="custom-calendar-input"
      onClick={onClick}
      ref={() => {
        ref;
      }}
    >
      <FontAwesomeIcon icon={faCalendarDays} />
      <p className="calendar-value">{value}</p>
    </button>
  ));

  return (
    <div className="articles-planning">
      <div className="header-content">
        <p className="header-title">Articles Planning</p>
        <div className="filters">
          <DatePicker
            showPopperArrow={false}
            selected={planningDate}
            onChange={(date: Date) => handleChangeDate(date)}
            customInput={<CustomInput />}
          />
          <FancyDropdown
            value={platform.label}
            options={platformOptions}
            getValue={(obj: any) => {
              handleChangePlatform(obj);
            }}
          ></FancyDropdown>
          {printPublicationsCategoriesOptions ? (
            <MultiDropdown
              placeholdar="Select categories"
              // value={publicationsCategory.label}
              selectedValues={publicationsCategory}
              options={printPublicationsCategoriesOptions}
              handleAddItem={(obj: any) => {
                handleAddPublicationsCategory(obj);
              }}
              handleRemoveItem={(obj: any) => {
                handleRemovePublicationsCategory(obj);
              }}
              handleRemoveAll={() => handleRemoveAllPublicationsCategory()}
            ></MultiDropdown>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className="main-content">
        <div className="elements-table">
          {tableData.map((listItem, index) => {
            if (listItem.main.itemName) {
              return (
                <ArticlesPlanningItem
                  listItem={listItem}
                  key={`${listItem.main.folio}-${listItem.main.itemName}`}
                  handleChangeGraphWidth={handleChangeGraphWidth}
                  handleChangeGraphHeight={handleChangeGraphHeight}
                  handleChangeAdditionalImage={handleChangeAdditionalImage}
                  zIndex={100 - index}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default ArticlesPlanning;
