import { CustomField } from '../../types';
export const printMetadataTypeDev: { [key: string]: string } = {
  '120_AUFM': 'primary',
  '110_AUFM_gross': 'primary',
  '120_AUFM_standard': 'primary',
  '210_AUFM_gross_MEI': 'primary',
  '220_AUFM_standard_MEI': 'primary',
  '125_Co-Text': 'secondary',
  '130_ABM': 'secondary',
  '230_ABM_MEI': 'secondary',
  '330_ABM_oLEAD_KXT': 'secondary',
  '430_ABM_KOMM_KXT': 'secondary',
  '140_DRITTST / Spalte': 'news',
  '140_DRITTST_oLEAD': 'news',
  '340_DRITTST_oLEAD_KXT': 'news',
  '440_DRITTST_KOMM_KXT': 'news',
  '110_PRIM_large': 'primary',
  '120_PRIM_standard': 'primary',
  '130_SEC': 'secondary',
  '140_THIRD': 'news',
  '140_THIRD_COL': 'news',
  '210_PRIM_large_OPI': 'primary',
  '220_PRIM_standard_OPI': 'primary',
  '230_SEC_OPI': 'secondary',
  '240_THIRD_COL_OPI': 'news',
  '430_SEC_COM_CXT': 'secondary',
  '440_THIRD_COM_CXT': 'news'
};
export const deskNetElementStatusDev: { [key: string]: string } = {
  '1': 'Pitch',
  '2': 'Accepted',
  '3': 'Rejected',
  '4': 'No Status',
  '11342': 'Idee',
  '11351': 'Akzeptiert',
  '11352': 'Entwurf',
  '11362': 'Gegenlesen',
  '11461': 'Produktion',
  '11462': 'Korrektorat',
  '11472': 'Nachbearbeitung',
  '11482': 'Bereit zum Publizieren',
  '11491': 'Verworfen',
  '13161': 'Idée',
  '13181': 'Accepté',
  '13171': 'Rejeté',
  '13182': 'Brouillon',
  '13191': 'Relecture',
  '13201': 'Edition',
  '13211': 'Correction',
  '13221': 'Révision',
  '13231': 'Prêt à publier'
};
export const deskNetPublicationStatusDev: { [key: string]: string } = {
  '1': 'No Status',
  '26081': 'A_Gegenlesen Print',
  '26091': 'A_Produktion Print',
  '26101': 'A_Redaktion Print',
  '26102': 'A_Gelesen Print',
  '26112': 'A_Nachbearbeitung Print',
  '26121': 'A_Fertig',
  '26131': 'Nicht publiziert',
  '26141': 'A_Korrektur Print',
  '26181': 'Auto-Scheduled',
  '30202': 'Publiziert',
  '33532': 'A_Layout_Print',
  '28731': 'Non publié',
  '28722': 'Auto-Scheduled',
  '30251': 'Publié',
  '28672': 'A_Redaction_print',
  '28682': 'A_Relecture_print',
  '28692': 'A_Edition_print',
  '28702': 'A_Correction_print',
  '28711': 'A_Verification_print',
  '28712': 'A_Relu_print',
  '28721': 'A_Pret'
};
export const publicationOnlineCodeDev: string = '9402102';
export const publicationFrOnlineCodeDev: string = '9416461';
export const deskNetPlatformsDev: { [key: string]: string } = {
  // '9402082': 'Newsletter',
  // '9402102': 'Online DE',
  // '9405061': 'Social Media',
  '9407452': 'MANTEL',
  '9402092': 'Tages-Anzeiger',
  '9407721': 'Berner Zeitung',
  '9407722': 'Basler Zeitung',
  '9407731': 'Zürcher Regionalzeitungen',
  '9407732': 'SonntagsZeitung',
  // '9407751': 'Das Magazin',
  '9422921': 'Finanz und Wirtschaft',
  '9478872': 'Journaux Romandie'
  // '9426891': 'ZüriTipp'
  //'9416461': 'Online FR',
};
export const deskNetTypesDev: { [key: string]: string } = {
  '9296721': '120_AUFM > DELETE',
  '9296731': '130_ABM',
  '9296741': '140_DRITTST / Spalte > DELETE',
  '9296961': '125_Co-Text > DELETE',
  '9297311': '120_AUFM',
  '9297341': '130_ABM',
  '9297342': '125_Co-Text',
  '9297352': '140_DRITTST / Spalte',
  '9297551': '120_AUFM',
  '9297561': '130_ABM',
  '9297572': '140_DRITTST / Spalte',
  '9297722': '120_AUFM',
  '9297732': '130_ABM',
  '9297741': '140_DRITTST / Spalte',
  '9297752': '125_Co-Text',
  '9297891': '120_AUFM',
  '9297902': '130_ABM',
  '9297922': '140_DRITTST / Spalte',
  '9298092': '120_AUFM',
  '9298101': '130_ABM',
  '9298102': '140_DRITTST / Spalte',
  '9298171': '125_Co-Text',
  '9298282': '110_Aufmacher',
  '9298292': '150_Abmacher',
  '9298302': '211_Kolumne',
  '9317341': '125_Co-Text',
  '9317351': '120_AUFM',
  '9317361': '130_ABM',
  '9317371': '140_DRITTST',
  '9325051': '125_Co-Text',
  '9347101': '1sp_Print',
  '9350402': '110_AUFM_gross',
  '9350412': '120_AUFM_standard',
  '9350422': '210_AUFM_gross_MEI',
  '9350432': '220_AUFM_standard_MEI',
  '9350442': '230_ABM_MEI',
  '9350452': '330_ABM_oLEAD_KXT',
  '9350462': '430_ABM_KOMM_KXT',
  '9350472': '140_DRITTST_oLEAD',
  '9350482': '340_DRITTST_oLEAD_KXT',
  '9350492': '440_DRITTST_KOMM_KXT',
  '9373012': '110_PRIM_large',
  '9373022': '120_PRIM_standard',
  '9374382': '130_SEC',
  '9375012': '140_THIRD',
  '9375022': '1col_Print',
  '9375032': '140_THIRD_COL',
  '9375042': '210_PRIM_large_OPI',
  '9375052': '220_PRIM_standard_OPI',
  '9375062': '230_SEC_OPI',
  '9375072': '240_THIRD_COL_OPI',
  '9375082': '430_SEC_COM_CXT',
  '9375092': '440_THIRD_COM_CXT'
};
export const deskNetCategoriesDev: { [key: string]: string } = {
  '10212422': 'Front-TAG',
  '10212432': 'Meinungen-TAG',
  '10212522': 'Switzerland',
  '10212532': 'International',
  '10212542': 'Economy',
  '10230431': 'Debatte',
  '10230672': 'Sport',
  '10230731': 'Trending topics',
  '10237271': 'M_Geld',
  '10237311': 'M_Politik',
  '10237351': 'International',
  '10237391': 'M_Sport_CH',
  '10237431': 'M_Kehrseite',
  '10238531': 'Zürich',
  '10238572': 'Front',
  '10238581': 'Meinungen',
  '10238582': 'Finale',
  '10238592': 'Emmental',
  '10238601': 'Magazin',
  '10238602': 'Oberaargau',
  '10238611': 'Fotografie',
  '10238621': 'Ausgehen',
  '10238631': 'Region Bern',
  '10238641': 'Samstagsstrecke',
  '10238651': 'Stadt Bern',
  '10238671': 'Front-BAZ',
  '10238682': 'Fasnacht',
  '10238711': 'Meinungen und Profile',
  '10238721': 'Region',
  '10238731': 'Region Kultur',
  '10238741': 'Region Thema',
  '10238742': 'Region Wirtschaft',
  '10238751': 'Regionalsport',
  '10238761': 'Front',
  '10238762': 'Winterthur',
  '10238771': 'Zürich',
  '10238781': 'Forum',
  '10238791': 'Plakat',
  '10238821': 'Region',
  '10238831': 'Regionalsport',
  '10238841': 'Samstag extra',
  '10238852': 'Front-SOZ',
  '10238862': 'Nachrichten',
  '10238871': 'Fokus',
  '10238881': 'Sport',
  '10238882': 'Leben & Kultur',
  '10238892': 'Wirtschaft',
  '10238901': 'Wissen',
  '10238912': 'Reisen',
  '10239601': 'Front-MAG',
  '10239602': 'Editorial',
  '10239611': 'Binswanger',
  '10239621': 'Gespräch',
  '10239631': 'Geschichte',
  '10270872': 'Instagram',
  '10270882': 'Facebook',
  '10270892': 'Twitter',
  '10280842': 'Sunday Paper',
  '10280852': 'Das Magazin',
  '10353512': 'Rätsel',
  '10353522': 'Tag im Leben',
  '10353532': 'Diverses',
  '10374691': 'Abstimmungen',
  '10374771': 'Basel',
  '10374812': 'Zurich',
  '10374822': 'Science',
  '10374831': 'Digital',
  '10374832': 'Blogs',
  '10374841': 'Lifestyle',
  '10374842': 'Opinion',
  '10374852': 'M_Zürich',
  '10374891': 'M_Hintergrund',
  '10374892': 'M_Kultur',
  '10374932': 'Panorama',
  '10374941': 'Travel',
  '10375161': 'Regionalsport',
  '10375211': 'Region',
  '10375471': 'Front-FUW',
  '10375481': 'Meinung',
  '10375511': 'Unternehmen',
  '10375671': 'Märkte',
  '10379721': 'Bern',
  '10379731': 'Emmental',
  '10379741': 'Oberaargau',
  '10379742': 'Berner Oberland',
  '10379751': 'Thun',
  '10381131': 'America',
  '10381132': 'Culture & Entertainment',
  '10394891': 'Événement',
  '10394921': 'M_Sport_ZH',
  '10394961': 'M_Sport_BE',
  '10395142': 'Cover',
  '10395152': 'Editorial',
  '10395162': 'Grosse Geschichte',
  '10395172': 'Mittlere Geschichte',
  '10395182': 'Kopf der Woche',
  '10395192': 'Kino / Magnet',
  '10395202': 'Stil & Shopping',
  '10395212': 'Körper & Karma',
  '10395222': 'Musik',
  '10395232': 'Bühne',
  '10395242': 'Kunstmagnet',
  '10395252': 'Gastro',
  '10395262': 'Familienbande',
  '10395272': 'Fotokolumne',
  '10395282': 'Kolumne',
  '10395292': 'Agenda',
  '10395302': 'Bilder',
  '10395312': 'Die Karte',
  '10395322': 'Die Liste',
  '10395332': 'Inhalt',
  '10432261': 'Upfront',
  '10445791': 'M_Seite3',
  '10445801': 'M_Wochenende',
  '10446321': 'Forum',
  '10535401': 'Des Planets',
  '10756911': '234',
  '10756931': 'ttt',
  '10237321': 'M_Politik-Gold',
  '10237331': 'M_Politik-Silber',
  '10237341': 'M_Politik-Bronze',
  '10237361': 'M_International-Gold',
  '10237371': 'M_International-Silber',
  '10237381': 'M_International-Bronze',
  '10237401': 'M_Sport_CH-01',
  '10237411': 'M_Sport_CH-02',
  '10237421': 'M_Sport_CH-03',
  '10240792': 'Ausgehen-01',
  '10240802': 'Ausgehen-02',
  '10240822': 'Region Bern-01',
  '10240832': 'Region Bern-02',
  '10240842': 'Region Bern-03',
  '10374772': 'Region-01',
  '10374782': 'Region-02',
  '10374792': 'Region-03',
  '10374802': 'Region-04',
  '10374862': 'M_Zürich-Gold',
  '10374872': 'M_Zürich-Silber',
  '10374882': 'M_Zürich-Bronze',
  '10374902': 'M_Kultur-Gold',
  '10374912': 'M_Kultur-Silber',
  '10374922': 'M_Kultur-Bronze',
  '10375171': 'Regionalsport-Stadt',
  '10375181': 'Regionalsport-EM',
  '10375191': 'Regionalsport-OA',
  '10375201': 'Regionalsport-BOM',
  '10375221': 'Region-TT',
  '10375261': 'Region-BO',
  '10375301': 'Front-BZST',
  '10375311': 'Front-BUND',
  '10375321': 'Front-BZBO',
  '10375331': 'Front-BZTT',
  '10375341': 'Magazin-Auftakt',
  '10375351': 'Magazin-02',
  '10375361': 'Magazin-03',
  '10375371': 'Oberaargau-Kanton 01',
  '10375381': 'Oberaargau-Kanton 02',
  '10375391': 'Oberaargau-Kanton 03',
  '10375401': 'Region Bern-04',
  '10375411': 'Stadt Bern-Doppelseite',
  '10375421': 'Stadt Bern-01',
  '10375431': 'Stadt Bern-02',
  '10375441': 'Stadt Bern-03',
  '10375451': 'Stadt Bern-04',
  '10375461': 'Stadt Bern-05',
  '10375491': 'Meinung-Seite 2',
  '10375501': 'Meinung-Seite 3',
  '10375521': 'Unternehmen-Seite 5',
  '10375531': 'Unternehmen-Seite 6',
  '10375541': 'Unternehmen-Seite 7',
  '10375551': 'Unternehmen-Seite 8',
  '10375561': 'Unternehmen-Seite 9',
  '10375571': 'Unternehmen-Seite 10',
  '10375581': 'Unternehmen-Seite 11',
  '10375591': 'Unternehmen-Seite 12',
  '10375601': 'Unternehmen-Seite 13',
  '10375611': 'Unternehmen-Seite 14',
  '10375621': 'Unternehmen-Seite 15',
  '10375631': 'Unternehmen-Seite 16',
  '10375641': 'Unternehmen-Seite 17',
  '10375651': 'Unternehmen-Seite 18',
  '10375661': 'Unternehmen-Seite 19',
  '10375681': 'Märkte-Seite 15',
  '10375691': 'Märkte-Seite 16',
  '10375701': 'Märkte-Seite 17',
  '10375711': 'Märkte-Seite 18',
  '10375721': 'Märkte-Seite 19',
  '10375731': 'Märkte-Seite 20',
  '10375741': 'Märkte-Seite 21',
  '10375751': 'Märkte-Seite 22',
  '10375761': 'Märkte-Seite 23',
  '10375771': 'Märkte-Seite 24',
  '10375781': 'Märkte-Seite 25',
  '10375791': 'Märkte-Seite 26',
  '10375801': 'Märkte-Seite 27',
  '10383242': 'Front-LAB',
  '10383252': 'Front-ZSZ',
  '10383262': 'Front-ZUM',
  '10383272': 'Winterthur-01',
  '10383282': 'Winterthur-02',
  '10383292': 'Winterthur-03',
  '10383302': 'Winterthur-04',
  '10383322': 'Zürich-01',
  '10383332': 'Zürich-02',
  '10383342': 'Zürich-03',
  '10383362': 'Plakat-LAB',
  '10383372': 'Plakat-ZSZ',
  '10383382': 'Plakat-ZUM',
  '10383452': 'Region-LAB-01',
  '10383462': 'Region-LAB-02',
  '10383472': 'Region-LAB-03',
  '10383482': 'Region-LAB-04',
  '10383502': 'Region-LAB',
  '10383512': 'Region-ZSZ-01',
  '10383522': 'Region-ZSZ-02',
  '10383532': 'Region-ZSZ-03',
  '10383542': 'Region-ZSZ-04',
  '10383562': 'Region-ZSZ',
  '10383572': 'Region-ZUM-01',
  '10383582': 'Region-ZUM-02',
  '10383592': 'Region-ZUM-03',
  '10383602': 'Region-ZUM-04',
  '10383622': 'Region-ZUM',
  '10383632': 'Regionalsport-ZRZ',
  '10383642': 'Regionalsport-LAB',
  '10383652': 'Regionalsport-ZSZ',
  '10383662': 'Regionalsport-ZUM',
  '10383671': 'Forum-LAB',
  '10383681': 'Forum-ZSZ',
  '10394901': 'Événement-01-02',
  '10394911': 'Événement-03-04',
  '10394931': 'M_Sport_ZH-01',
  '10394941': 'M_Sport_ZH-02',
  '10394951': 'M_Sport_ZH-03',
  '10394971': 'M_Sport_BE-BEZ-01',
  '10394981': 'M_Sport_BE-BEZ-02',
  '10394991': 'M_Sport_BE-BEZ-03',
  '10394992': 'Zürich-EVE',
  '10395002': 'Zürich-01-TAG',
  '10395012': 'Zürich-02-TAG',
  '10395022': 'Zürich-03-TAG',
  '10395032': 'Zürich-04-TAG',
  '10395041': 'Region-Buspi',
  '10395051': 'Region-05',
  '10395061': 'Region Kultur-01',
  '10395071': 'Region Kultur-02',
  '10395081': 'Region Kultur-03',
  '10395091': 'Regionalsport-01',
  '10395101': 'Regionalsport-02',
  '10395111': 'Regionalsport-03',
  '10395121': 'Regionalsport-04',
  '10395131': 'Regionalsport-05',
  '10395141': 'Regionalsport-06',
  '10461341': 'M_Kultur-Kupfer',
  '10461351': 'M_Kultur-Eisen',
  '10461371': 'M_Sport_CH-04',
  '10461381': 'M_Sport_CH-05',
  '10461391': 'M_Sport_ZH-04',
  '10461401': 'M_Sport_BE-BEZ-04',
  '10461411': 'M_Sport_BE-BEZ-05',
  '10461421': 'M_Sport_BE-BEZ-06',
  '10461431': 'M_Sport_BE-BEZ-07',
  '10461441': 'M_Sport_BE-BUN-01',
  '10461451': 'M_Sport_BE-BUN-02',
  '10461461': 'M_Sport_BE-BUN-03',
  '10461471': 'M_Sport_BE-BUN-04',
  '10461481': 'M_Sport_BE-BUN-05',
  '10461491': 'M_Sport_BE-BUN-06',
  '10737551': 'M_Politik-Kupfer',
  '10737561': 'M_Politik-Eisen',
  '10767612': 'M_Zürich-Kupfer',
  '10826332': 'M_International-Kupfer',
  '10826342': 'M_International-Eisen',
  '11261522': 'Une-JRO',
  '11261532': 'Une-24H',
  '11261542': 'Une-TDG',
  '11261552': 'Opinions',
  '11261562': 'Opinions-24H',
  '11261572': 'Opinions-TDG',
  '11264661': 'Point fort-24H',
  '11264671': 'Vaud-JRO',
  '11264681': 'Vaud-01-24H',
  '11264691': 'Vaud-02-24H',
  '11264701': 'Vaud-03-24H',
  '11264711': 'Événement-TDG',
  '11264721': 'Genève-JRO',
  '11264731': 'Genève-01-TDG',
  '11264741': 'Genève-02-TDG',
  '11264751': 'Genève-03-TDG',
  '11261582': 'M_Actu-JRO',
  '11264611': 'M_Actu-01-24H',
  '11264621': 'M_Actu-02-24H',
  '11264631': 'M_Actu-03-24H',
  '11264641': 'M_Actu-04-24H',
  '11264651': 'M_Actu-05-24H',
  '11264761': 'M_Sports_CH-JRO',
  '11264771': 'M_Sports_CH-01-24H',
  '11264781': 'M_Sports_CH-02-24H',
  '11264791': 'M_Sports_CH-03-24H',
  '11264801': 'Sports_VD-JRO',
  '11264811': 'Sports_VD-01-24H',
  '11264821': 'Sports_VD-02-24H',
  '11264831': 'Sports_VD-03-24H',
  '11264841': 'Sports_GE-JRO',
  '11264851': 'Sports_GE-01-TDG',
  '11264861': 'Sports_GE-02-TDG',
  '11264871': 'Sports_GE-03-TDG',
  '11264881': 'M_Culture-JRO',
  '11264891': 'M_Culture-01-24H',
  '11264901': 'M_Culture-02-24H',
  '11264911': 'M_Culture-03-24H'
};
export const deskNetCustomFieldsDev: CustomField[] = [
  {
    id: 3951,
    active: false,
    type: 3,
    name: 'Tenants',
    options: [
      {
        id: 8871,
        name: 'BO'
      },
      {
        id: 10631,
        name: 'BZ'
      },
      {
        id: 10641,
        name: 'BaZ'
      },
      {
        id: 10651,
        name: 'Bund'
      },
      {
        id: 10661,
        name: 'LB'
      },
      {
        id: 10671,
        name: 'LT'
      },
      {
        id: 10681,
        name: 'TA'
      },
      {
        id: 10691,
        name: 'TT'
      },
      {
        id: 10701,
        name: 'ZSZ'
      },
      {
        id: 10711,
        name: 'ZU'
      }
    ]
  },
  {
    id: 3952,
    active: true,
    type: 2,
    name: 'Vorlage',
    options: [
      {
        id: 8872,
        name: 'Standard'
      },
      {
        id: 8882,
        name: 'Meinung'
      },
      {
        id: 8892,
        name: 'Interview'
      },
      {
        id: 8902,
        name: 'Listicle'
      },
      {
        id: 22102,
        name: 'Faktencheck'
      },
      {
        id: 22112,
        name: 'Einzelkritiken'
      },
      {
        id: 22122,
        name: 'Leer'
      },
      {
        id: 44231,
        name: 'Liveticker'
      }
    ]
  },
  {
    id: 4021,
    active: true,
    type: 2,
    name: '#Typ',
    options: [
      {
        id: 9091,
        name: 'A-Story'
      },
      {
        id: 9111,
        name: 'Mögliche A-Story'
      },
      {
        id: 25111,
        name: 'Für T11 geeignet'
      },
      {
        id: 25121,
        name: 'Repost'
      }
    ]
  },
  {
    id: 4192,
    active: true,
    type: 1,
    name: '#Push-Vorschlag (126)'
  },
  {
    id: 4202,
    active: true,
    type: 1,
    name: '#Link zu Original W-CH'
  },
  {
    id: 4331,
    active: true,
    type: 2,
    name: 'Abo / Paywall',
    options: [
      {
        id: 10771,
        name: 'Ja'
      },
      {
        id: 10781,
        name: 'Nein'
      }
    ]
  },
  {
    id: 5922,
    active: true,
    type: 2,
    name: '#Online-Platzierung',
    options: [
      {
        id: 16262,
        name: 'erledigt'
      },
      {
        id: 16272,
        name: 'offen'
      },
      {
        id: 23692,
        name: 'T11 platziert'
      },
      {
        id: 23702,
        name: 'A platziert'
      }
    ]
  },
  {
    id: 8482,
    active: true,
    type: 2,
    name: '-----',
    options: [
      {
        id: 26072,
        name: '-----'
      }
    ]
  },
  {
    id: 9461,
    active: true,
    type: 2,
    name: 'Haupt-Mandant',
    options: [
      {
        id: 29271,
        name: 'Tages-Anzeiger'
      },
      {
        id: 29281,
        name: 'Der Landbote'
      },
      {
        id: 29291,
        name: 'Zürcher Unterländer'
      },
      {
        id: 29301,
        name: 'Zürichsee-Zeitung'
      },
      {
        id: 29311,
        name: 'Berner Zeitung'
      },
      {
        id: 29321,
        name: 'Berner Oberländer'
      },
      {
        id: 29331,
        name: 'Langenthaler Tagblatt'
      },
      {
        id: 29341,
        name: 'Thuner Tagblatt'
      },
      {
        id: 29351,
        name: 'Der Bund'
      },
      {
        id: 29361,
        name: 'Basler Zeitung'
      },
      {
        id: 29371,
        name: '12-App'
      },
      {
        id: 29381,
        name: 'Finanz und Wirtschaft'
      }
    ]
  },
  {
    id: 10281,
    active: true,
    type: 2,
    name: '#Print Priorität',
    options: [
      {
        id: 35301,
        name: 'Print offen'
      },
      {
        id: 35311,
        name: '1'
      },
      {
        id: 35321,
        name: '2'
      },
      {
        id: 43111,
        name: '3'
      }
    ]
  },
  {
    id: 15671,
    active: true,
    type: 2,
    name: 'Textlänge',
    options: [
      {
        id: 67371,
        name: 'S - 2000'
      },
      {
        id: 67381,
        name: 'M - 4000'
      },
      {
        id: 67391,
        name: 'L - 6000'
      },
      {
        id: 67401,
        name: 'XL - 8000'
      },
      {
        id: 67411,
        name: 'XXL - 15000'
      }
    ]
  },
  {
    id: 6911,
    active: true,
    type: 2,
    name: 'Modèle',
    options: [
      {
        id: 20541,
        name: 'Standard'
      },
      {
        id: 20551,
        name: 'Listicle'
      },
      {
        id: 20561,
        name: 'Interview'
      },
      {
        id: 20571,
        name: 'Opinion'
      },
      {
        id: 22051,
        name: 'Vérification des faits'
      },
      {
        id: 22061,
        name: 'Critiques individuelles'
      },
      {
        id: 22071,
        name: 'Vide'
      },
      {
        id: 44271,
        name: 'Liveticker'
      }
    ]
  },
  {
    id: 6921,
    active: false,
    type: 3,
    name: 'Tenants',
    options: [
      {
        id: 20591,
        name: '24h'
      },
      {
        id: 20601,
        name: 'TdG'
      },
      {
        id: 20611,
        name: 'LM'
      },
      {
        id: 20621,
        name: 'BIL'
      }
    ]
  },
  {
    id: 6931,
    active: true,
    type: 2,
    name: '#Placement',
    options: [
      {
        id: 20631,
        name: 'ouvert'
      },
      {
        id: 20641,
        name: 'placé T15'
      },
      {
        id: 26591,
        name: 'placé A'
      },
      {
        id: 26601,
        name: 'placé'
      }
    ]
  },
  {
    id: 6932,
    active: true,
    type: 1,
    name: '#Lien original D-CH'
  },
  {
    id: 6942,
    active: true,
    type: 1,
    name: '#Proposition Push (126)'
  },
  {
    id: 7002,
    active: true,
    type: 2,
    name: 'Premium',
    options: [
      {
        id: 20822,
        name: 'Non'
      },
      {
        id: 20832,
        name: 'Oui'
      }
    ]
  },
  {
    id: 8632,
    active: true,
    type: 2,
    name: '-----',
    options: [
      {
        id: 26602,
        name: '-----'
      }
    ]
  },
  {
    id: 8641,
    active: true,
    type: 2,
    name: '#Type',
    options: [
      {
        id: 26611,
        name: 'A-Story'
      },
      {
        id: 26621,
        name: 'Possible A-Story'
      },
      {
        id: 26631,
        name: 'Utilisable pour T15'
      },
      {
        id: 26641,
        name: 'Repost'
      }
    ]
  },
  {
    id: 9531,
    active: true,
    type: 2,
    name: 'Site web principal',
    options: [
      {
        id: 29971,
        name: '24 heures'
      },
      {
        id: 29981,
        name: 'Tribune de Genève'
      },
      {
        id: 29991,
        name: 'Bilan'
      }
    ]
  },
  {
    id: 10671,
    active: false,
    type: 4,
    name: 'Datum'
  },
  {
    id: 11981,
    active: true,
    type: 2,
    name: '#Priorité print',
    options: [
      {
        id: 44961,
        name: 'ouverte'
      },
      {
        id: 44971,
        name: '1'
      },
      {
        id: 44981,
        name: '2'
      },
      {
        id: 44991,
        name: '3'
      }
    ]
  },
  {
    id: 15682,
    active: true,
    type: 2,
    name: 'Nombre de caractères',
    options: [
      {
        id: 67462,
        name: 'S - 2000'
      },
      {
        id: 67472,
        name: 'M - 4000'
      },
      {
        id: 67482,
        name: 'L - 6000'
      },
      {
        id: 67492,
        name: 'XL - 8000'
      },
      {
        id: 67502,
        name: 'XXL - 15000'
      }
    ]
  }
];
export const elementStatusColorDev: { [key: string]: string } = {
  Idee: '#ffb2dc',
  Akzeptiert: '#82e580',
  Entwurf: '#fee900',
  Gegenlesen: '#ffb346',
  Produktion: '#01a5f0',
  Korrektorat: '#b56eef',
  Nachbearbeitung: '#ff5f62',
  'Bereit zum Publizieren': '#47994d',
  Verworfen: '#081d42',
  Idée: '#ffb2dc',
  Accepté: '#82e580',
  Brouillon: '#fee900',
  Relecture: '#ffb346',
  Edition: '#01a5f0',
  Correction: '#b56eef',
  Révision: '#ff5f62',
  'Prêt à publier': '#47994d',
  Rejeté: '#081d42'
  // ## not used:
  // 'Pitch' : "#01a5f0",
  // 'Accepted' : "#82e580",
  // 'Rejected' : "#ff5f62",
  // 'No Status' : "#FFFFFF",
};
export const printPublicationsCategoriesDev: { [key: string]: string[] } = {
  MANTEL: [
    'Börse',
    'Geld & Recht',
    'Hintergrund',
    'International',
    'Kehrseite',
    'Kultur, Gesellschaft & Wissen',
    'Mobil',
    'Politik & Wirtschaft',
    'Seite Drei',
    'Sport_BE',
    'Sport_CH',
    'Sport_ZH',
    'Wochenende',
    'MAN_Zürich',
    'Rätsel',
    'Anzeige'
  ],
  'Tages-Anzeiger': [
    'Agenda',
    'Diverses',
    'Forum',
    'Front',
    'Kooperation',
    'Meinungen',
    'Spezial',
    'TV & Radio',
    'Wetter',
    'Zürich',
    'Leserforum',
    'Alpha',
    'Börse',
    'Geld & Recht',
    'Hintergrund',
    'International',
    'Kehrseite',
    'Kultur, Gesellschaft & Wissen',
    'Mobil',
    'Politik & Wirtschaft',
    'Seite Drei',
    'Sport_BE',
    'Sport_CH',
    'Sport_ZH',
    'Wochenende',
    'MAN_Zürich',
    'Rätsel',
    'Anzeige'
  ],
  'Berner Zeitung': [
    '65 plus',
    'Agenda',
    'Diverses',
    'Emmental',
    'Finale',
    'Forum',
    'Fotografie',
    'Front',
    'Hintergrund Bund',
    'Kanton Bern',
    'Kino',
    'Magazin',
    'Meinungen',
    'Oberaargau',
    'Pfeffer',
    'Region',
    'Region Bern',
    'Regionalsport',
    'Samstagsinterview',
    'Stadt Bern',
    'Thema',
    'TV & Radio',
    'TV Woche',
    'Unterhaltung',
    'Wetter',
    'Börse',
    'Geld & Recht',
    'Hintergrund',
    'International',
    'Kehrseite',
    'Kultur, Gesellschaft & Wissen',
    'Mobil',
    'Politik & Wirtschaft',
    'Seite Drei',
    'Sport_BE',
    'Sport_CH',
    'Sport_ZH',
    'Wochenende',
    'MAN_Zürich',
    'Rätsel',
    'Anzeige'
  ],
  'Basler Zeitung': [
    'Denksport',
    'Diverses',
    'Fasnacht',
    'Fernsehen Tipps',
    'Front',
    'Kino',
    'Meinungen und Profile',
    'Region',
    'Region Agenda',
    'Region Thema',
    'Regionalsport',
    'Wetter',
    'Börse',
    'Geld & Recht',
    'Hintergrund',
    'International',
    'Kehrseite',
    'Kultur, Gesellschaft & Wissen',
    'Mobil',
    'Politik & Wirtschaft',
    'Seite Drei',
    'Sport_BE',
    'Sport_CH',
    'Sport_ZH',
    'Wochenende',
    'MAN_Zürich',
    'Rätsel',
    'Anzeige'
  ],
  'Zürcher Regionalzeitungen': [
    'Agenda',
    'Dorfleben',
    'Forum',
    'Front',
    'Horgen',
    'Leserbriefe',
    'Marktplatz',
    'Meilen',
    'Region',
    'Regionalsport',
    'Samstag extra',
    'Schaufenster',
    'Treffpunkt',
    'TV / Radio',
    'Wetter',
    'Winterthur',
    'Zürich',
    'Börse',
    'Geld & Recht',
    'Hintergrund',
    'International',
    'Kehrseite',
    'Kultur, Gesellschaft & Wissen',
    'Mobil',
    'Politik & Wirtschaft',
    'Seite Drei',
    'Sport_BE',
    'Sport_CH',
    'Sport_ZH',
    'Wochenende',
    'MAN_Zürich',
    'Rätsel',
    'Anzeige'
  ],
  SonntagsZeitung: [
    'Auto',
    'Extra',
    'Fokus',
    'Front',
    'Gesellschaft',
    'Kultur',
    'Leben & Kultur',
    'Nachrichten',
    'Reisen',
    'Sport',
    'Wirtschaft',
    'Wissen',
    'ALPHA',
    'Anzeige'
  ],
  'Finanz und Wirtschaft': [
    'Frontseite',
    'Import',
    'Märkte',
    'Meinung',
    'Monitor',
    'Unternehmen',
    'Anzeige'
  ],
  'Journaux Romandie': [
    'Une',
    'Actu',
    'Arc lémanique',
    'Genève',
    'Opinions',
    'Point fort',
    'Sports_CH',
    'Sports_GE',
    'Sports_VD',
    'Vaud',
    'Événement',
    'Agenda',
    'Cinéma',
    'Culture',
    'Jeux/Météo',
    'Sortir ce week-end',
    'Auto',
    'Bons plans',
    'Emploi',
    'Gastronomie',
    'Immobilier',
    'Signé Genève',
    'Dossier',
    'Magazine',
    'Mortuaires',
    'Pub'
  ]
};
